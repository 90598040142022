.Polaris-Icon {
  margin: 0 0.25rem;
}
.remove-icon-margin .Polaris-Icon {
  margin: 0;
}
.date-picker-btn-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
}
.Polaris-DatePicker {
  margin-top: 0.5rem;
}
.Polaris-ShadowBevel,
.Polaris-Box {
  overflow: visible;
  border-radius: 12px;
}

.box-banner-container .Polaris-Box {
  border-radius: 12px;
}

.Polaris-Modal-Dialog__Modal {
  .Polaris-Box {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .Polaris-InlineStack .Polaris-Box {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .Polaris-Banner .Polaris-Box {
    border-radius: 12px;
  }
}

.d-flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
.font-bold {
  font-weight: bold;
}
.content-right {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.mt-4 {
  margin-top: 0.25rem;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mt-16 {
  margin-top: 1rem;
}
.mt-14 {
  margin-top: calc(1rem * 14 / 16);
}

.ml-4 {
  margin-left: 0.25rem;
}
.ml-8 {
  margin-left: 0.5rem;
}
.ml-16 {
  margin-left: 1rem;
}

.mr-4 {
  margin-right: 0.25rem;
}
.mr-8 {
  margin-right: 0.5rem;
}
.mr-16 {
  margin-right: 1rem;
}
.mb-4 {
  margin-bottom: 0.25rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.pb-16 {
  padding-bottom: 1rem;
}
.pb-8 {
  padding-bottom: 0.5rem;
}
.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}
.w-full {
  width: 100%;
}
.w-fit {
  width: fit-content;
}
.w-50 {
  width: 50%;
}
.f-wrap {
  flex-wrap: wrap;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.py-8 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.Polaris-LegacyStack__Item:has(button[aria-disabled='true']) {
  display: none;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.nowrap {
  flex-wrap: nowrap;
}
.wrap {
  flex-wrap: wrap;
}

.gap-4 {
  gap: 0.25rem;
}

.gap-8 {
  gap: 0.5rem;
}

.gap-10 {
  gap: calc(10 / 16 * 1rem);
}

.gap-14 {
  gap: calc(14 / 16 * 1rem);
}

.gap-16 {
  gap: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-column {
  flex-direction: column;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}

.text-pre-line {
  white-space: pre-line;
}

.overflow-wrap-break {
  overflow-wrap: break-word;
}

.text-line-through {
  text-decoration: line-through;
}

.over .cursor-pointer {
  cursor: pointer;
}

/* Agent modal */

.agent-avatar {
  padding: 3px;
}
.agent-avatar:hover {
  border: 3px solid;
  border-radius: 6px;
  color: #ddd;
  padding: 0px;
}
.agent-avatar-active {
  border: 3px solid;
  border-radius: 6px;
  color: #4959bd;
}
.agent-avatar-active:hover {
  color: #4959bd;
}
.agent-avatar-container {
  position: relative;
}
.agent-avatar-selected {
  position: absolute;
  top: 0rem;
  right: 1rem;
}

.Polaris-Modal-Dialog__Modal:has(#modal-promo-quantity-break) {
  width: 29rem;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.Polaris-Modal-Dialog__Modal:has(#modal-popup-return-drive) {
  width: 31rem;
  @media (max-width: 767px) {
    width: 100%;
  }
}

#subscribed-button-email .Polaris-ActionList__Prefix,
#subscribed-button-sms .Polaris-ActionList__Prefix {
  display: none !important;
}

.Polaris-Modal-Dialog__Modal.Polaris-Modal-Dialog--sizeLarge:has(#modal-create-edit-form) {
  width: 95vw;
  max-width: unset;
  @media (max-width: 767px) {
    width: 100%;
  }
  .Polaris-InlineStack > h2.Polaris-Text--root {
    width: 100%;
  }
}

.Polaris-Modal-Dialog__Container:has(#edit-contact-form-confirm-id),
.Polaris-Modal-Dialog__Container:has(#edit-contact-form-discard-id) {
  z-index: 520;
}

div:has(.Polaris-Modal-Dialog__Container):has(#edit-contact-form-confirm-id) + .Polaris-Backdrop,
div:has(.Polaris-Modal-Dialog__Container):has(#edit-contact-form-discard-id) + .Polaris-Backdrop {
  z-index: 519;
}
